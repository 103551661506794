import { createAction, props } from '@ngrx/store';
import { AwbShipping } from '../../interfaces/awb-shipping.interface';

export enum AwbShippingActionsTypes {
    getAwbShipping = '[AwbShipping] Get Awb Shipping',
    successGetShipping='[AwbShipping] Success Get Awb Shipping',
    errorGetShipping='[AwbShipping] Error Get Awb Shipping' ,

    createAwb = '[Awb] Create Awb',
    successCreateAwb = '[Awb] Success Create Awb',
    errorCreateAwb = '[Awb] Error Create Awb',

    updateAwb = '[Awb] Update Awb',
    successUpdateAwb = '[Awb] Success Update Awb',
    errorUpdateAwb = '[Awb] Error Get Awb'
}

export const getAwbShipping = createAction(AwbShippingActionsTypes.getAwbShipping,props<{filters:string}>());
export const successGetAwbShipping= createAction(AwbShippingActionsTypes.successGetShipping,props<{ awb_shipping: Array<AwbShipping> }>());
export const errorGetAwbShipping = createAction(AwbShippingActionsTypes.errorGetShipping, props<{ payload: any }>());

export const createAwb = createAction(AwbShippingActionsTypes.createAwb, props<{ Awb: AwbShipping }>());
export const successCreateAwb = createAction(AwbShippingActionsTypes.successCreateAwb, props<{ Awb: AwbShipping }>());
export const errorCreateAwb = createAction(AwbShippingActionsTypes.errorCreateAwb, props<{ payload: any }>());

export const updateAwb = createAction(AwbShippingActionsTypes.updateAwb, props<{ Awb: AwbShipping, id: string }>());
export const successUpdateAwb = createAction(AwbShippingActionsTypes.successUpdateAwb, props<{ Awb: AwbShipping }>());
export const errorUpdateAwb = createAction(AwbShippingActionsTypes.errorUpdateAwb, props<{ payload: any }>());